body.contact {
  background-image: url(images/contact/contact-bg.png);
  background-size: cover;
  background-repeat: no-repeat;

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .contact-hero {
    flex: 1;
  }
}

.contact-ctas {
  margin-top: 24px;
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  max-width: 580px;

  a{
    &:hover{
      text-decoration: none;
    }
  }
}

.contact-cta {
  background: $darkgreen;
  transition: $transition-quick;
  font-family: 'freight-comp';
  font-style: normal;
  font-size: 28px;
  font-weight: 100;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;

  visibility: hidden;


  &:hover {
    background: $green;
    opacity: 1;
  }

  @media (max-width: $break-small) {
    font-size: 20px;
  }
}
