nav {
  position: fixed;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  padding-bottom: 50px;

  @media (max-width: $break-small) {
    display: block;
    margin: 0 auto;
  }

  .nav-wrap-left {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40%;
    max-width: 600px;
    height: 100px;

    margin: 0;

    @media (max-width: $break-small) {
      margin: 0 auto;
      width: 100%;
      max-width: 300px;
      height: 75px;
    }

    video {
      width: 100%;
      height: 100px;
      margin: 0 0 0 20px;

      @media (max-width: $break-small) {
        height: 50px;
        margin: 0;
      }
    }
  }

  .nav-wrap-right {
    display: flex;
    align-items: center;

    margin: 0 0 0 0;

    @media (max-width: $break-small) {
      margin: 0;
    }

    z-index: 200;

    @media (max-width: $break-small) {
      justify-content: center;
    }

    div {
      margin: 0 2vw 0 0;

      @media (max-width: $break-small) {
        margin: 0 5px;
      }
    }
  }
}






.nav-overlay{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
}

.closebtn{
  svg{
    stroke: black;

    &:hover{
      stroke: $lilac !important;
    }
  }
}

.openbtn{
  svg{
    stroke: black;
    transition: $transition-quick;

    &:hover{
      stroke: $lilac !important;
    }

    .black & {
      stroke: white;
    }
  }
}



.overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: black;
  background-color: rgba(255, 255, 255, 0.95);
}

.overlay-content {
  position: relative;
  top: 10vh;
  width: 100%;
  text-align: center;

  .overlay-item{
    margin-bottom: -40px;
    font-size: clamp(40px, 8vw, 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-quick;

    @media (max-width: $break-small) {
      font-size: $body-size-mobile;
    }

    img{
      width: 35vw;
      max-width: 200px;
      margin: 0;
    }

    p{
      font-size: $body-size;
    }

    &:hover{
        color: $lilac;
    }
  }

  
}

.overlay a {
  padding: 0px;
  text-decoration: none;
  color: black;
  display: block;
  transition: $transition-quick;
}


.overlay .closebtn{
  position: absolute;
  top: 3vh;
  right: 3vh;

  @media (max-width: $break-small) {
    top: 3vh;
    right: calc(50vw - 20px);
  }
}

.nav-overlay .openbtn {
  position: relative;
  top: 3vh;
  right: 3vh;

  @media (max-width: $break-small) {
    top: 3vh;
    right: calc(50vw - 20px);
  }
}


