:root {
  --text-fg: black;
}

.video-target {
  position: absolute;
  aspect-ratio: 42/9;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: rgba(red, 0.5);
  pointer-events: none;

  @media (max-width: $break-small) {
    aspect-ratio: 1;
  }
}

.home-img {
  position: absolute;
  padding: 0;
  z-index: 10;

  @media (max-width: $break-small) {
    padding: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .home-rollover {
    opacity: 0;
    transition: opacity 500ms ease;
  }

  &:hover {
    .home-rollover {
      opacity: 1;
    }
  }
}



.home-img-1,
.home-img-2,
.home-img-3,
.home-img-4,
.home-img-5{
  width: 90px;

  @media (max-width: $break-small) {
    display: none;
  }
}

.home-img-1 {
  top: 8%;
  left: 5%;

  span{
    top: 10px;
    left: -10%;
  }
}

.home-img-2 {
  top: 15%;
  left: 45%;

  span{
    top: 5px;
    left: -40%;
  }
}

.home-img-3 {
  top: 12%;
  left: 86%;

  span{
    top: 10px;
    left: 5%;
  }
}

.home-img-4 {
  bottom: 12%;
  left: 2%;

  span{
    top: -5px;
    left: 50%;
  }
  
  video{
    transform: rotate(-10deg);
  }
}

.home-img-5 {
  bottom: 12%;
  right: 2%;

  span{
    top: -20px;
    left: -80%;
  }
  
  video{
    transform: rotate(30deg);
  }
}
