$break-small: 700px;
$break-med: 900px;
$green: #dcff71;
$darkgreen: #85e5ac;
$lilac: #e2e2ed;
$peach: #fcf2e4;

$transition: all 1500ms ease 1000ms;
$transition-quick: all 300ms ease;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
  color: black;
  transition: $transition;
}

::selection {
  background: $green;
}

// VIDEO

#desktop-video {
  display: block;

  @media (max-width: $break-small) {
    display: none;
  }
}

#mobile-video {
  display: none;

  @media (max-width: $break-small) {
    display: block;
  }
}

.video-wrap-full {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 95vw;
  height: 100vh;

  margin: auto;

  @media (max-width: $break-small) {
    margin: -15vh auto;
  }

  .desktop-video-icon {
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
    // opacity: 0;
    // animation: onload 500ms ease-in-out 1 forwards;

    @media (max-width: $break-small) {
      display: none;
    }
  }

  .video-logo {
    width: 100%;
    margin: 0 0 0 0;
    opacity: 0;
    animation: onload 500ms ease-in-out 1 forwards;

    @media (max-width: $break-small) {
      animation: none;
      opacity: 1;
      width: 85%;
      margin: 0 auto;
    }
  }
}

@keyframes onload {
  from {
    opacity: 0;
    margin-top: 20px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.leader-line path,
.leader-line use[href='#leader-line-disc'] {
  transition: $transition;
}

// NEWSLETTER

.home-tagline {
  text-align: center;
  font-family: 'freight-comp';
  font-style: normal;
  font-size: 42px;
  font-weight: 100;
  text-transform: none;
  transition: $transition;
  margin-bottom: 24px;

  @media (min-width: ($break-small + 1px)) and (max-width: $break-med) {
    font-size: 36px;
  }

  @media (max-width: $break-small) {
    font-size: 30px;
    margin-bottom: 16px;
  }

  .black & {
    color: white;
  }
}

.newsletter {
  position: absolute;

  width: 100%;
  margin: 10px 0;

  top: 75vh;
  left: 0;
  right: 0;

  @media (max-width: $break-small) {
    top: 60vh;
  }

  form {
    position: relative;
    text-align: center;
    display: block;
    width: 75%;
    margin: 0 auto;

    @media (max-width: $break-small) {
      display: block;
    }

    input {
      border: none;
      background-color: transparent;
      width: 100%;

      text-align: center;
      text-transform: uppercase;

      transition: $transition;
      font-size: 16px;

      @media (max-width: $break-small) {
        width: 100%;
        text-align: center;
        padding: 0 0 10px;
      }

      input::-webkit-input-placeholder {
        opacity: 1;
        color: black;
      }

      &:focus::placeholder {
        color: rgba($color: #000000, $alpha: 0.1);
      }

      &:focus {
        outline: none;
        border: none;
        // border-bottom: black 1px solid;
        min-width: 235px;

        @media (max-width: $break-small) {
          width: 90%;
        }
      }
    }

    .klaviyo_messages {
      text-transform: uppercase;
      font-size: 0.9em;

      & > div {
        margin: 0 0 10px;
      }
    }

    .klaviyo_field_wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $break-small) {
        display: block;
      }
    }

    button {
      border: none;
      background-color: transparent;
      color: black;
      cursor: pointer;

      transition: $transition;

      &:hover {
        transition: opacity ease 400ms;
        opacity: 0.5;
      }
    }
  }
}

// FOOTER

footer {
  width: 100%;
  padding: 20px;
  position: relative;
  bottom: 0;

  display: flex;
  justify-content: space-between;

  .contact {
    right: 0;
  }
}

.home footer {
  position: absolute;

  a{
    transition: $transition;
  }
}

// MISC

.black {
  background-color: black;
  color: white;

  a,
  form {
    color: white;

    button,
    input {
      color: white;
    }
  }
}
