.founder{
    background-color: $lilac;
  }

.founder nav {
  background: $lilac;
  background: linear-gradient(180deg, rgba(226, 226, 237, 100) 0%, rgba(226, 226, 237, 0) 100%);
}

.pill-links{
  margin: 50px 0;
  
  a{
    background-color: $green;
    color: black;

    &:hover{
      text-decoration: none;
      background-color: $darkgreen;
    }
  }
}

.speaking-wrap{
  margin: 50px 0;
}