.mission {
  background-color: $peach;
}

.mission nav {
  background: $peach;
  background: linear-gradient(180deg, rgba(252, 242, 228, 100) 0%, rgba(252, 242, 228, 0) 100%);
}

.mission-img {
  display: block;
  height: auto;
  max-width: 100%;
}

.mission {
  h2 {
    margin: 0 0 30px 0;

    @media (max-width: $break-small) {
      margin: 0 0 15px 0;
    }
  }
}
.mission-top {
  width: 100%;
}

.mission-bottom {
  display: flex;

  p {
    width: 1vw;
    margin: 0px 20px 0 0;
  }
}

.mission-hero {
  overflow: hidden;
  margin: 0;
  padding: 50px 5vw 0;
}

.mission-hero-img {
  width: 60vw;
  margin: -20vw -20vw 0 auto;

  @media (max-width: $break-small) {
    width: 80vw;
    margin: -8vw -20vw 0 auto;
  }
}


.mission-copy-block {
  padding: 50px 5vw;
  flex: 1;

  p:has(a) {
    margin-top: -12px;
  }

  a {
    font-size: $detail-size;
  }

  .mission-copy-block-inner {
    max-width: 505px;
  }

  &--left {
    .mission-copy-block-inner {
      margin-left: auto;
    }
  }

  &--right {
    .mission-copy-block-inner {
      margin-right: auto;
    }
  }
}

.mission-section {
  overflow: hidden;
  margin: -15vw 0 0;
  padding: 5vw 0;

  @media (max-width: $break-small) {
    margin: 0;
    padding: 0;
  }
}

.mission-grid {
  display: flex;
  align-items: center;

  @media (max-width: $break-small) {
    display: block;
  }

  .mission-img {
    width: 50vw;
    transform: scale(1.3);

    @media (max-width: $break-small) {
      transform: none;
      width: 100%;
      margin-bottom: -20vw;
    }

    &--right {
      order: 999;
    }
  }
}


.reveal-mission{
  visibility: hidden;
}