@font-face {
  font-family: 'america';
  font-weight: 200;
  src: local('america'), url('/src/assets/fonts/GT-America-Light.woff') format('woff');
}


@font-face {
  font-family: 'freight-comp';
  font-weight: 200;
  src: url('/src/assets/fonts/FreightDisplayCompressed-BookItalic.woff') format('woff'),
                         url('/src/assets/fonts/FreightDisplayCompressed-BookItalic.woff2') format('woff2');
}


$body-size: 20px;
$body-size-mobile: 16px;

$detail-size: 14px;

body{
  font-family: 'america';
  font-size: $body-size;
  font-weight: 200;
  line-height: 1.2;

  transition: $transition;

  @media (max-width: $break-small) {
    font-size: $body-size-mobile;
  }
}

p{
  line-height: 1.35;
  margin: 0 0 10px 0;
}

a,
form {
  text-decoration: none;
  color: black;

  font-family: 'america';
  font-size: $detail-size;

  transition: none;

  @media (max-width: $break-small) {
    font-size: $detail-size;
  }
}

a {
  &:hover {
    // opacity: 0.5;
    text-decoration: underline;
  }
  
  video{
    &:hover{
      opacity: 1;
    }
  }
}



h1{
  font-family: 'america';
  font-size: clamp(40px, 8vw, 100px);
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
}

h2{
  font-family: 'freight-comp';
  font-size: clamp(28px, 5vw, 72px);
  font-weight: 100;
  margin: 0 0 10px;
  letter-spacing: 1px;
  line-height: 1;
}


.patent-info{
  font-size: $detail-size;
  // opacity: 50%;
}

h3{
  font-family: 'freight-comp';
  font-style: italic;
  font-size: clamp(28px, 6vw, 90px);
  font-weight: 100;
  line-height: .95;
  text-align: left;

  margin: 0;
}


h4{
  font-family: 'freight-comp';
  font-style: italic;
  font-size: 30px;
  font-weight: 100;

  margin: 0 0 10px 0;
}

em{
  font-family: 'america';
  font-size: $detail-size;
  font-weight: 100;
  font-style: normal;
  margin: 0;
  vertical-align: top;

  // opacity: .5;
}

.tm{
  position: relative;
  top: 20px;
}

.tm-title{
  position: relative;
  top: 10px;
  font-size: 2vw;
}

.details{
  font-family: 'america';
  font-size: $detail-size;
  text-transform: uppercase;
  font-weight: 100;
  margin: 0;
  vertical-align: top;
  
  margin-bottom: 10px;
}

.launch-title{
  // font-size: $body-size;
}

h3.reveal-launches{
  font-family: 'america';
  font-style: normal;
  font-size: 40px;
  text-transform: uppercase;
}


.home-rollover{
  font-family: 'america';
  font-size: $detail-size;
  font-weight: 100;
  margin: 0;

  position: relative;

  text-transform: uppercase;
}

.pill{
  font-family: 'america';
  font-size: $detail-size;
  font-style: normal;
  font-weight: 100;
  text-transform: uppercase;

  padding: 3px 10px;
  margin: 0 5px 10px 0;
  
  position: relative;
  display: inline-block;

  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 100px;
}

.project-links{
  font-size: $detail-size;
  text-transform: uppercase;
}

footer a{
  font-size: $body-size;
}