$tan: #fdf1e1;
$acid-green: #d2ff52;
$deep-green: #5ee8a7;


.sequence{
  $speed: 20000ms;

  animation: background-fade-sequence-background $speed ease-in-out infinite;

  a{
    animation: background-fade-sequence-color $speed ease-in-out infinite;
  }

  form{
    animation: background-fade-sequence-color $speed ease-in-out infinite;

    input, input::-webkit-input-placeholder{
      animation: background-fade-sequence-color $speed ease-in-out infinite;
    }

    button{
      animation: background-fade-sequence-color $speed ease-in-out infinite;
    }
  }
}


@keyframes background-fade-sequence-color {
  25% {color: black;}
  50% {color: white;}
  75% {color: black;}
}


.flip{
  $speed: 28000ms;

  animation: background-flip-background $speed ease-in-out infinite;

  a{
    animation: background-flip-color $speed ease-in-out infinite;
  }

  form{
    animation: background-flip-color $speed ease-in-out infinite;

    input, input::-webkit-input-placeholder{
      animation: background-flip-color $speed ease-in-out infinite;
    }

    button{
      animation: background-flip-color $speed ease-in-out infinite;
    }
  }
}

@keyframes background-flip-background {
  0% {background-color: white;}
  45% {background-color: white;}
  50% {background-color: black;}
  95% {background-color: black;}
  100% {background-color: white;}
}

@keyframes background-flip-color {
  0% {color: black;}
  45% {color: black;}
  50% {color: white;}
  95% {color: white;}
  100% {color: black;}
}



.alternate{
  $speed: 60000ms;

  animation: background-fade-alternate-background $speed ease-in-out infinite;
}

@keyframes background-fade-alternate-background {
  0% {background-color: white;}
  12.5% {background-color: $lilac;}
  25% {background-color: white;}
  37.5% {background-color: $tan;}
  50% {background-color: white;}
  62.5% {background-color: $acid-green;}
  75% {background-color: white;}
  87.5% {background-color: $deep-green;}
  100% {background-color: white;}
}
