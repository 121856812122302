$page-margin: 5vw;
$copy-max: 530px;

header {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

section {
  margin: 50px $page-margin;
  padding: 0;
}

.header-type {
  width: 100vw;
  height: 13vh;
  margin: 0;
  padding: 0;

  @media (max-width: $break-small) {
    height: 10vh;
  }
}

.project-title {
  margin: 0 0 50px;

  .project-title-pill{
    margin: 0 10px 0 0;
    background-color: $green;
  }
}

.project-info {
  width: 100%;
  display: flex;

  @media (max-width: $break-small) {
    display: block;
  }

  .project-info-left {
    width: 50%;

    @media (max-width: $break-small) {
      width: 100%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 10px 0;
      }
    }

    .patent-info {
      margin: 25px 0;

      .patent-title {
        text-transform: uppercase;
      }

      p {
        margin: 10px 0;

        span {
          width: 150px;
          display: inline-block;
        }
      }
    }
  }

  .project-info-right {
    width: 50%;

    @media (max-width: $break-small) {
      width: 100%;
    }

    .logo-hero {
      max-width: 400px;
    }

    img {
      width: 100%;
      margin: 0 auto;
      padding-left: 5%;
      display: block;

      @media (max-width: $break-small) {
        margin: 50px auto;
        padding-left: 0;
      }
    }
  }

  .project-links {
    width: 50%;

    @media (max-width: $break-small) {
      width: 100%;
    }

    div {
      margin: 0 0 50px 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0 0 10px 0;
          max-width: 80%;

          @media (max-width: $break-small) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.intro {
  display: flex;
  justify-content: space-between;
  margin: 100px $page-margin;

  @media (max-width: $break-small) {
    display: block;
    margin: 100px $page-margin;
  }
}

.quote-section {
  margin: 200px 0;
}

.quote {
  position: relative;

  .quote-large {
    width: 100%;
  }

  .quote-left {
    width: 50%;

    @media (max-width: $break-small) {
      width: 100%;
      padding: 50px 0;
    }
  }

  .quote-large-copy {
    margin-left: 20vw;

    @media (max-width: $break-small) {
      margin: 0 $page-margin;
    }
  }

  .quote-large-left-copy {
    margin-left: $page-margin;

    @media (max-width: $break-small) {
      margin: 50px $page-margin;
    }
  }

  .quote-logo {
    margin-top: 50px;
    width: 100%;
    max-width: 250px;

    @media (max-width: $break-small) {
      margin-top: 20px;
      max-width: 100px;
    }
  }
}

.quote-gradient-wrap {
  position: absolute;
  inset: 0;
  display: flex;
}

.quote-gradient-wrap-left {
  left: -10vw;
  top: 10vw;

  @media (max-width: $break-small) {
    left: 50vw;
    top: 10vw;
  }
}

.quote-gradient-wrap-large {
  left: -70vw;
  top: 5vw;

  @media (max-width: $break-small) {
    left: 50vw;
    top: 10vw;
  }
}

.quote-gradient-wrap-large-left {
  left: 60vw;
  top: 2vw;

  @media (max-width: $break-small) {
    left: 50vw;
    top: 10vw;
  }
}

.quote-gradient {
  display: block;
  width: 40vw;
  height: 40vw;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  z-index: -1;
  background: $green;
  border-radius: 1000px;
  filter: blur(100px);

  @media (max-width: $break-small) {
    filter: blur(60px);
  }
}

.intro-copy {
  width: 50%;
  max-width: $copy-max;

  @media (max-width: $break-small) {
    width: 100%;
    margin: 100px 0;
  }
}

.content-wrap {
  display: flex;
  justify-content: space-between;

  @media (max-width: $break-small) {
    display: block;
  }

  .full-image {
    width: 100%;
  }

  .content-image {
    // width: 50%;

    img {
      width: 100%;
    }
  }

  .block-copy {
    // width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    @media (max-width: $break-small) {
      margin: 20px 0;
    }

    .block-copy-wrap{
      max-width: $copy-max;
    }
  }

  & > div {
    &:first-child {
      width: 47.5%;
      margin-right: 2.5%;

      @media (max-width: $break-small) {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 100px;
      }
    }

    &:last-child {
      width: 47.5%;
      margin-left: 2.5%;

      @media (max-width: $break-small) {
        width: 100%;
        margin-left: 0%;
      }
    }
  }
}

.development-title {
  display: flex;
  align-items: center;

  margin: 0 0 50px;

  @media (max-width: $break-small) {
    flex-wrap: wrap; 
  }

  h3{
    @media (max-width: $break-small) {
      margin-bottom: 25px;
    }
  }

  h3,
  img,
  p {
    margin-right: 40px;
  }

  p {
    @media (max-width: $break-small) {
      width: 100%;
    }
  }

  img {
    max-height: 70px;
    transform: rotate(-25deg);
  }

  .development-icon{
    animation: spin 30s linear infinite;
  }

  .details{
    margin-bottom: 0;

    @media (max-width: $break-small) {
      margin: 20px 0;
    }

    .pill{
      margin-bottom: 0;

      @media (max-width: $break-small) {
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes spin { 
  0% { 
    transform:rotate(0deg); 
  } 
  100% { 
    transform:rotate(-360deg); 
  } 
}

.before {
  margin: 200px $page-margin;
}

// .after {
// }

.image-wrap-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;

  @media (max-width: $break-small) {
    display: block;
  }

  div {
    width: 100%;

    img {
      max-width: 100%;
      // padding: 0 5px;
      width: 100%;

      @media (max-width: $break-small) {
        padding: 0;
      }
    }

    &:first-child {
      padding: 0 5px 0 0;

      @media (max-width: $break-small) {
        padding: 0;
      }
    }

    &:last-child {
      padding: 0 0 0 5px;

      @media (max-width: $break-small) {
        padding: 0;
      }
    }
  }

  .full-image {
    padding: 0;
    margin: 0;

    img {
      padding: 0;
      margin: 0;
    }

    &:first-child {
      padding: 0;
    }

    &:last-child {
      padding: 0;
    }
  }
}

.awards-press {
  background-color: $lilac;

  margin: 0;
  padding: $page-margin;
}
