.image-slider {
  .slider-pagination {
    margin-top: 20px;
  }

  .swiper-button-lock,
  .swiper-pagination-lock {
    pointer-events: none;
    visibility: invisible;
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 0 6px;
    background: white;
    border: 1px solid black;
    border-radius: 50%;
    transition: $transition-quick;

    &:hover {
      background: black;
    }
  }

  .swiper-pagination-bullet-active {
    background: black;
  }
}
