.shhhowercap-header{
    background-image: url(assets/images/launches/shhhowercap/deejayzoo-shhhowercap-launch-header-1.jpg);
    background-size: cover;
    background-position: center center;

    @media (max-width: $break-small) {
        background-image: url(assets/images/launches/shhhowercap/deejayzoo-shhhowercap-launch-header-2.jpg);
    }
}

.shhhowercap nav {
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 100) 0%, rgba(255, 255, 255, 0) 100%);
}



.video-launches{
    max-width: 100%;
    width: 100%;
    height: auto;
}

.video-wrap{
    width: 100%;
}

.shhhowercap{
    footer{
        background-color: $lilac;
    }
}